import { call, takeEvery, put, select, fork, all, delay, ForkEffect } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { get, find, isEqual } from 'lodash'
import { actionsHandler } from 'services/apiServices/api'
import * as transferApi from 'services/apiServices/transfer.service'
import {
  ITransferData,
  ICurrencyPayload,
  IPriceResponse,
  IPricePayload,
  ILimitPayload,
  ITransactionMeta,
  IDTOneProduct,
  IDTOnePayload,
  clearPrice,
  IDTOneResponse,
  IMobileOperator,
} from 'model/transfer.model'
import { IBankAccount } from 'model/recipient.model'
import { UPLOAD_TYPES } from 'model/user.model'
import { ITransactionDetail } from 'model/transaction.model'
import * as transferConstants from 'constants/transfer.constants'
import { REQUEST, SUCCESS, FAIL } from 'constants/global.constants'
import {
  setSourceCurrencies,
  setDestinationCurrencies,
  setTransferData,
  setCalculatorData,
  setTransactionMeta,
  getPriceActions,
  getLocationsSettingsActions,
  getLocationsActions,
  getBanksActions,
  getBranchesActions,
  getCityLocationsActions,
  getCitiesActions,
  getMobileOperatorsActions,
  makeTransactionActions,
  confirmTransactionActions,
  validateTransactionActions,
  getDTOneDataActions,
  getCountryStatesActions,
  getStateCitiesActions,
  getStateCityLocationsActions,
  getDiscountActions,
  applyComplianceRule,
} from '../reducers/transfer.reducer'
import { closeModal, closeSpinner, openModal, openSpinner, openToast } from '../reducers/ui.reducer'
import { editRecipientRequest } from '../reducers/recipient.reducer'
import { getWalletsRequest } from '../reducers/wallet.reducer'
import { uploadTransactionDocumentRequest, getCurrentTransactionSuccess } from '../reducers/transaction.reducer'
import { getPhoneNumberObject } from './user.sagas'
import ConfirmTransaction from 'features/ModalContent/ConfirmTransaction'
import i18n from 'locales/i18n'

const organizationId: string = process.env.REACT_APP_ORGANIZATION_ID as string

export function* getSourceCurrenciesSaga(payload: ICurrencyPayload) {
  try {
    const currencies: string[] = yield call(transferApi.getSourceCurrencies, payload)
    yield put(setSourceCurrencies(currencies))
    yield put(
      setTransferData({
        sourceCurrency: currencies.length === 1 ? currencies[0] : currencies.includes('ILS') ? 'ILS' : '',
      })
    )
    if ((transferApi.getSourceCurrencies.cache as any).size > 50) {
      transferApi.getSourceCurrencies.cache.clear && transferApi.getSourceCurrencies.cache.clear()
    }
  } catch (err) {
    yield put(setSourceCurrencies([]))
    yield put(
      setTransferData({
        sourceCurrency: '',
      })
    )
  }
}

export function* getDestinationCurrenciesSaga(payload: ICurrencyPayload) {
  try {
    const currencies: string[] = yield call(transferApi.getDestinationCurrencies, payload)
    yield put(setDestinationCurrencies(currencies))
    yield put(
      setTransferData({
        destinationCurrency: currencies.length === 1 ? currencies[0] : '',
      })
    )
    if ((transferApi.getDestinationCurrencies.cache as any).size > 50) {
      transferApi.getDestinationCurrencies.cache.clear && transferApi.getDestinationCurrencies.cache.clear()
    }
  } catch (err) {
    yield put(setDestinationCurrencies([]))
    yield put(
      setTransferData({
        destinationCurrency: '',
      })
    )
  }
}

export function* getPriceLimit(payload: ILimitPayload) {
  try {
    const { data: limitResponse } = yield call(transferApi.getTransactionLimit, payload)
    if (limitResponse.amount) {
      yield put(setTransferData({ limit: limitResponse.amount }))
    } else {
      yield put(setTransferData({ limit: '' }))
    }
    if ((transferApi.getTransactionLimit.cache as any).size > 50) {
      transferApi.getTransactionLimit.cache.clear && transferApi.getTransactionLimit.cache.clear()
    }
  } catch (err) {
    yield put(setTransferData({ limit: '' }))
  }
}

function* getDToneDataSaga({ payload }: PayloadAction<Omit<IDTOnePayload, 'url'>>) {
  try {
    const { token } = yield select((state) => state.user)
    const { mode, amountReceivedDest } = yield select(({ transfer }) => transfer.transferData)
    if (!payload.phoneNumber) {
      return
    }
    const url = `${token ? 'operator/' : 'unAuth/'}getCellphoneOperatorProducts`
    const response: IDTOneResponse = yield call(transferApi.getDTOneData, { ...payload, url })
    yield put(getDTOneDataActions[SUCCESS](response))

    if (mode === 'repeat' && amountReceivedDest) {
      const topupInfo = response.products.find((product: IDTOneProduct) => +product.receivedAmount === amountReceivedDest)
      if (!!topupInfo) {
        yield put(
          setTransferData({
            amountSentSrc: get(topupInfo, 'sentAmount', 0),
            amountReceivedDest: get(topupInfo, 'receivedAmount', 0),
            senderToPaySrc: get(topupInfo, 'senderToPay', 0),
            destinationCurrency: get(topupInfo, 'receivedCurrency', ''),
            sourceCurrency: get(topupInfo, 'sendCurrency', ''),
            organizationFeeAmountSrc: get(topupInfo, 'fee', 0),
            fxRateSrcDestWithCommission: get(topupInfo, 'fxRate', 0),
          })
        )
      }
    }
  } catch (err) {
    yield put(getDTOneDataActions[FAIL]())
  }
}

export function* makeCalculationSaga({ payload }: PayloadAction<Partial<ITransferData>>) {
  try {
    const { transferData } = yield select((state) => state.transfer)
    const { agentId, transferDataByCountry } = yield select((state) => state.configuration)

    const data = { ...transferData, ...payload }

    if (payload.payoutType && !payload.mode && !payload.operatorId) {
      const operators = transferDataByCountry[data.destinationCountry as string][payload.payoutType as string]
      if (operators && operators.length === 1) {
        yield put(
          setTransferData({
            operatorId: operators[0],
          })
        );
        data.operatorId = operators[0];
      }
    }

    if (payload.mode && payload.mode === 'repeat') {
      if (transferDataByCountry[payload.destinationCountry as string]) {
        yield put(setTransferData({ destinationCountry: payload.destinationCountry, mode: payload.mode }))
        const payoutTypes = Object.keys(transferDataByCountry[payload.destinationCountry as string])
        if (payoutTypes.includes(payload.payoutType as string)) {
          yield put(setTransferData({ payoutType: payload.payoutType }))
          const operators = transferDataByCountry[payload.destinationCountry as string][payload.payoutType as string]
          if (operators.includes(payload.operatorId)) {
            yield put(
              setTransferData({
                operatorId: payload.operatorId,
                payinType: payload.payinType,
              })
            )
          } else {
            yield put(
              setTransferData({
                payinType: '',
                operatorId: '',
                destinationCurrency: '',
                sourceCurrency: '',
                reverse: false,
                mode: '',
                ...clearPrice,
              })
            )
            return
          }
        } else {
          yield put(
            setTransferData({
              payoutType: '',
              payinType: '',
              operatorId: '',
              destinationCurrency: '',
              sourceCurrency: '',
              reverse: false,
              mode: '',
              ...clearPrice,
            })
          )
          return
        }
      } else {
        yield put(
          setTransferData({
            destinationCountry: '',
            payoutType: '',
            payinType: '',
            operatorId: '',
            destinationCurrency: '',
            sourceCurrency: '',
            reverse: false,
            mode: '',
            ...clearPrice,
          })
        )
        return
      }
    }

    if (data.payoutType === 'AIR_TIME') {
      if (payload.mode === 'repeat') {
        yield put(
          setTransferData({
            amountReceivedDest: payload.amountReceivedDest,
          })
        )
      }
      if (payload.phoneNumber) {
        const phone = getPhoneNumberObject(payload.phoneNumber, data.destinationCountry)
        yield put(
          getDTOneDataActions[REQUEST]({
            operatorId: data.operatorId,
            phoneNumber: phone.number as string,
          })
        )
      }
      return
    }

    const enoughDataForRequest = Boolean(data.destinationCountry && data.payoutType && data.operatorId && data.sourceCountry)

    if (enoughDataForRequest && (payload.destinationCountry || payload.payoutType || payload.operatorId)) {
      const currenciesPayload = {
        agentId,
        organizationId,
        destinationCountry: data.destinationCountry,
        operatorId: data.operatorId,
        payoutType: data.payoutType,
        sourceCountry: data.sourceCountry,
      }
      yield* getSourceCurrenciesSaga(currenciesPayload)
      yield* getDestinationCurrenciesSaga(currenciesPayload)
      if (data.payoutType === 'CASH') {
        yield put(getLocationsSettingsActions[REQUEST]({
          country: data.destinationCountry,
          operatorId: data.operatorId,
        }))
      }
    } else if (!enoughDataForRequest) {
      return
    }

    if (data.destinationCurrency && enoughDataForRequest) {
      const limitPayload = {
        country: data.destinationCountry,
        currency: data.destinationCurrency,
        operatorId: data.operatorId,
        payoutType: data.payoutType,
      }
      yield getPriceLimit(limitPayload)
    }

    if (payload.mode && (payload.mode === 'repeat' || (payload.mode === 'previous' && !data.payoutType.includes('WALLET_DEPOSIT')))) {
      let counter = 0;
      let sourceList = [];
      let destinationList = [];
      while (counter < 80) {
        counter = counter + 1;
        const { destinationCurrencies, sourceCurrencies } = yield select(({ transfer }) => transfer.transferData)
        if (destinationCurrencies.length > 0 && sourceCurrencies.length > 0) {
          sourceList = sourceCurrencies;
          destinationList = destinationCurrencies;
          break;
        }
        yield delay(50);
      }
      if (payload.sourceCurrency && sourceList.includes(payload.sourceCurrency)) {
        yield put(setTransferData({ sourceCurrency: payload.sourceCurrency }));
      } else {
        yield put(setTransferData({ ...clearPrice, sourceCurrency: '' }));
        delete data.sourceCurrency;
      }
      if (payload.destinationCurrency && destinationList.includes(payload.destinationCurrency)) {
        yield put(setTransferData({ destinationCurrency: payload.destinationCurrency }));
      } else {
        yield put(setTransferData({ ...clearPrice, destinationCurrency: '', limit: '' }));
        delete data.destinationCurrency;
      }
      if (!!data.destinationCurrency && !!data.sourceCurrency && (Number(payload.amountReceivedDest || '0') > 0 || Number(payload.amountSentSrc || '0') > 0)) {
        yield put(
          setTransferData({
            amountReceivedDest: payload.amountReceivedDest,
            amountSentSrc: payload.amountSentSrc,
            reverse: payload.reverse,
          })
        )
        yield put(getPriceActions[REQUEST]())
      }
    } else if (payload.payinType && (Number(data.amountReceivedDest || '0') > 0 || Number(data.amountSentSrc || '0') > 0)) {
      yield put(getPriceActions[REQUEST]())
    }
  } catch (err) {
    console.log(err)
  }
}

export function* getDiscountSaga({ payload }: PayloadAction<{ promoCode: string }>) {
  try {
    const {
      destinationCountry,
      sourceCountry,
      payoutType,
      sourceCurrency,
      operatorId,
      payinType,
      amountSentSrc,
    } = yield select(({ transfer }) => transfer.transferData)
    const { agentId } = yield select((state) => state.configuration)

    const { data } = yield call(transferApi.getDiscountByPromoCode, {
      agentId,
      amount: amountSentSrc,
      destinationCountry,
      fundingSource: payinType,
      operatorId,
      payoutType,
      transactionOrigin: 'WEB',
      sourceCountry,
      sourceCurrency,
      ...payload
    });
    if (data.valid) {
      yield put(setCalculatorData({ promoCode: data.campaignData.promoCode || data.campaignData.promocode}));
      yield put(getDiscountActions[SUCCESS]({ promoCodeValid: true }));
      yield put(getPriceActions[REQUEST]())
    } else {
      yield put(getDiscountActions[SUCCESS]({ promoCodeValid: false }));
      yield put(openToast({
        type: 'success',
        toastMessage: 'promoCode:promo_code_not_active',
      }));
    }
  } catch (error) {
    yield put(getDiscountActions[FAIL]());
    yield put(openToast({
      type: 'error',
      toastMessage: 'errors:something_wrong',
    }));
  }
}

export function getRecipientAccountData(recipientAccounts: IBankAccount[], payoutType: string) {
  return find(
    recipientAccounts,
    account => account.payoutType === payoutType
  );
}

export function* makeNewTransactionSaga() {
  const transactionOrigin = 'WEB'
  try {
    yield put(closeModal())
    yield put(openSpinner())
    const { transferData } = yield select((state) => state.transfer)
    const { selectedRecipient: recipient } = yield select(({ recipients }) => recipients)
    const { agentId } = yield select((state) => state.configuration)
    const {userId} = yield select(({user}) => user)


    if (!recipient && (transferData.payoutType === 'WALLET_TO_WALLET' || transferData.payoutType === 'WALLET_EXCHANGE')) {
      yield delay(50);
      yield put(makeTransactionActions[REQUEST]());
      return;
    }

    const account = recipient && getRecipientAccountData(recipient.bankAccounts, transferData.payoutType);

    const { data } = yield call(transferApi.createTransaction, {
      senderId: userId,
      depositingAgentId: agentId,
      reverse: transferData.reverse,
      amount: transferData.amountSentSrc,
      amountSenderToPay: transferData.senderToPaySrc,
      amountDest: transferData.amountReceivedDest,
      sendCurrency: transferData.sourceCurrency,
      receiveCurrency: transferData.destinationCurrency,
      fundingSource: transferData.payinType,
      payoutType: transferData.payoutType,
      pickupCode: transferData.pickupCode || (transferData.pickupLocations && transferData.pickupLocations.length > 0 ? transferData.pickupLocations[0].locationCode : null),
      cashPickupName: transferData.cashPickupName || (transferData.pickupLocations && transferData.pickupLocations.length > 0 ? transferData.pickupLocations[0].locationName : null),
      cardNumber: transferData.cardNumber ? transferData.cardNumber.split(/\s+/).join('') : null,
      cardMask: transferData.cardMask,
      cardAccountId: transferData.cardAccountId ?? null,
      creditCardValidThru: transferData.creditCardValidThru,
      accountNumber: account ? account.accountNumber : undefined,
      recipientId: get(recipient, 'recipientId', ''),
      operatorToUseId: transferData.operatorId,
      pickupCityCode: get(transferData, 'pickupCityCode', null),
      pickupCityName: get(transferData, 'pickupCityName', null),
      pickupCorrespondentCode: get(transferData, 'pickupCorrespondentCode', null),
      mobileOperatorCode: get(transferData, 'mobileOperatorCode', null),
      mobileOperatorName: get(transferData, 'mobileOperatorName', null),
      transactionOrigin,
      promoCode: transferData.promoCode,
      useOfFunds: get(transferData, 'useOfFunds', 'Own Account transfer'),
      sourceOfFunds: get(transferData, 'sourceOfFunds', 'Salary'),
      occupation: get(transferData, 'occupation', 'SELF_EMPLOYED'),
      bankRoutingNumber: transferData.routingNumber,
      senderWalletId: get(transferData, 'senderWalletId', null),
      recipientWalletId: get(transferData, 'recipientWalletId', null),
      signature: transferData.signature,
      unsignedVerificationData: transferData.unsignedVerificationData,
      comment: transferData.comment,
    })

    const {
      transactionId,
      depositCode,
      receiveCurrency,
      validVolatility,
      priceResponse,
      validationResponseMessage,
      transactionStatus,
      message,
      status,
      failedComplianceRules,
    } = data

    if (status !== 'SUCCESS' && failedComplianceRules && failedComplianceRules.length > 0) {
      const { navigation } = yield select(({ ui }) => ui)
      yield put(makeTransactionActions[FAIL](message || ''))

      const rules = failedComplianceRules.reduce((acc: object, cur: any) => ({
        ...acc,
        [cur.action]: cur,
      }), {});

      if (rules.BLOCK) {
        yield put(
          applyComplianceRule({
            'BLOCK': { ...rules.BLOCK }
          })
        );

        navigation.navigate('/compliance-block');

      } else if (rules.CHECK_DOSSIER) {
        const { failedUserDocs } = rules.CHECK_DOSSIER.failedInfo;
        const missingDocuments = failedUserDocs
          .reduce((acc: object, doc: { failedUserDoc: string }) => ({
            ...acc,
            [doc.failedUserDoc.startsWith('KYC') ? 'KYC' : doc.failedUserDoc]: { ...doc }
          }), {});

        yield put(
          applyComplianceRule({
            'CHECK_DOSSIER': { ...missingDocuments }
          })
        );
        const documents = Object.keys(missingDocuments)

        if (documents.includes(UPLOAD_TYPES.SECOND_ID)) {
          navigation.navigate('/second-document')
        } else if (documents.includes('KYC')) {
          navigation.navigate('/kyc-questionnaire')
        } else {
          navigation.navigate('/transfer-failed')
        }
      } else {
        navigation.navigate('/transfer-failed')
      }
      return
    }

    if (status !== 'SUCCESS' || !depositCode) {
      throw new Error(message || i18n.t('transaction:something_went_wrong_please_try_again_later'))
    }

    if (!validVolatility) {
      throw new Error(i18n.t('errors:rate_volatility'))
    }

    const transactionMeta: ITransactionMeta = {
      transactionId,
      depositCode,
      transactionStatus,
      receiveCurrency,
      priceResponse,
      validationResponseMessage,
      agentId,
    }

    if (validationResponseMessage && validationResponseMessage.requiredFields && validationResponseMessage.requiredFields.length) {
      const isFilled = validationResponseMessage.requiredFields.every((field: any) => !!field.value)
      yield put(
        makeTransactionActions[SUCCESS]({
          ...transactionMeta,
          requiredFields: validationResponseMessage.requiredFields,
          validationPassed: false,
        })
      )
      if (isFilled) {
        yield put(validateTransactionActions[REQUEST]())
        return
      } else {
        const { navigation } = yield select(({ ui }) => ui)
        navigation.navigate('/additional-fields')
        return
      }
    }

    if (transferData.payoutType === 'WALLET_DEPOSIT_BANK' || transferData.payoutType === 'SALARY') {
      const { upload } = yield select(state => state.user);
      if (upload.INVOICE) {
        yield put(getCurrentTransactionSuccess({
          transactionId,
          payoutType: transferData.payoutType,
        } as ITransactionDetail));
        yield put(uploadTransactionDocumentRequest());
      }
    }

    yield put(makeTransactionActions[SUCCESS](transactionMeta))
  } catch (error) {
    const { navigation } = yield select(({ ui }) => ui)
    navigation.navigate('/transfer-failed')
    yield put(makeTransactionActions[FAIL](error.message || error.toString()))
  } finally {
    yield put(closeSpinner())
  }
}

export function* navigateAfterMakeTransaction() {
  yield delay(50)
  const { userMeta } = yield select(({ user }) => user)
  const { selectedRecipient } = yield select(({ recipients }) => recipients)
  const { transferData, transactionMeta } = yield select((state) => state.transfer)

  if (!!transactionMeta.requiredFields && !transactionMeta.validationPassed) {
    return
  }

  const { navigation } = yield select(({ ui }) => ui)

  if (transferData.payinType === 'CREDIT_CARD') {
    const linkPayload = {
      reservationCode: transactionMeta.depositCode,
      currency: transferData.sourceCurrency,
      amount: transferData.senderToPaySrc,
      documentNumber: process.env.NODE_ENV === 'production' ? userMeta.idNumber.replace(/([^\d]*)(\s*)/g, '') : '332707256',
      firstName: userMeta.firstName,
      lastName: userMeta.lastName,
      street: userMeta.address,
      city: userMeta.city,
      email: userMeta.email,
      phone: userMeta.phone,
      zip: userMeta.zipCode,
    }
    const {
      data: { pageUrl, status, message },
    } = yield call(transferApi.getPaymentLink, linkPayload)

    if (status !== 'SUCCESS') {
      yield put(closeSpinner())
      yield put(openToast({
        type: 'error',
        toastMessage: message || 'transaction:something_went_wrong_please_try_again_later',
      }));
      return;
    }

    yield put(setTransactionMeta({ ...transactionMeta, paymentLink: pageUrl }))
    navigation.navigate('/payment-by-other-card')
    yield put(closeSpinner())
  } else if (transferData.payinType === 'WALLET') {
    yield put(openModal({
      type: ConfirmTransaction,
      props: { name: 'ConfirmTransaction' },
      paperClass: 'qr-code-paper-modal',
    }))
  } else {
    yield put(closeSpinner())
    navigation.navigate('/transfer-finish')
  }

  if (transferData.payoutType === 'AIR_TIME' && transferData.phoneNumber && transferData.phoneNumber !== selectedRecipient.phoneNumber) {
    yield put(
      editRecipientRequest({
        recipientId: selectedRecipient.recipientId,
        country: selectedRecipient.country,
        phoneNumber: transferData.phoneNumber,
        firstName: selectedRecipient.firstName,
        lastName: selectedRecipient.lastName,
      })
    )
  }
}

export function* confirmTransactionSaga({ payload }: PayloadAction<any>) {
  try {
    const { userId } = yield select(({ user }) => user)
    const { agentId } = yield select((state) => state.configuration)
    const {
      transactionMeta,
      transferData: { cardNumber, cardHolderName, payinType, payoutType },
    } = yield select(({ transfer }) => transfer)

    const confirmPayload = {
      organizationId,
      transactionId: transactionMeta.transactionId,
      depositingUserId: userId,
      cardEnprintedName: cardHolderName,
      cardNumber: !cardNumber ? null : cardNumber.replace(/\s/g, ''),
      agentId,
      ...payload,
    }
    const { data } = yield call(transferApi.confirmTransaction, confirmPayload)

    const { status, transactionStatus, reservationCode, timeCreated } = data;

    const { navigation } = yield select(({ ui }) => ui)

    yield put(confirmTransactionActions[SUCCESS]({
      ...transactionMeta,
      transactionStatus,
      reservationCode,
      timeCreated,
    }))

    yield put(closeModal())

    if (status === 'SUCCESS' && !(transactionStatus || "").includes("COMPLIANCE")) {
      navigation.navigate('/transfer-finish')
    } else if (status === 'SUCCESS' && (transactionStatus || "").includes("COMPLIANCE")) {
      navigation.navigate('/transfer-finish')
    } else {
      throw new Error(data.message)
    }

    if (payinType === 'WALLET' || payoutType === 'WALLET_DEPOSIT_CARD' || payoutType === 'SALARY') {
      yield put(getWalletsRequest())
    }

  } catch (err) {
    yield put(confirmTransactionActions[FAIL](err.message || err.toString()))
    if (!(err.message || '').toLowerCase().includes("wrong verification code")) {
      const { navigation } = yield select(({ ui }) => ui)
      navigation.navigate('/transfer-failed')
      yield put(closeModal())
    }
  } finally {
    yield put(closeSpinner())
  }
}

export function* getLocationsSettingsSaga({ payload }: PayloadAction<{ country: string, operatorId: string }>) {
  try {
    const { agentId } = yield select(({ configuration }) => configuration);
    const { data: { hasMultiplePaymentType, cashPickupLocations } } = yield call(transferApi.getLocationsSettings, {
      agentId,
      ...payload,
    });
    if (!!cashPickupLocations && Object.keys(cashPickupLocations).length > 0) {
      const locationDetails = Object.keys(cashPickupLocations)
        .map(locationCode => ({
          locationCode,
          locationName: cashPickupLocations[locationCode],
        }));
      yield put(getLocationsActions[SUCCESS](locationDetails));
    } else {
      yield put(getLocationsActions[REQUEST]());
    }
    yield put(getLocationsSettingsActions[SUCCESS](Boolean(hasMultiplePaymentType)));
  } catch (err) {
    yield put(getLocationsSettingsActions[FAIL]());
  }
}

export function* getPricePayloadBody() {
  const { agentId } = yield select((state) => state.configuration)
  const { userId } = yield select((state) => state.user)
  const { transferData } = yield select((state) => state.transfer)
  const payload: IPricePayload = {
    agentId,
    amount: !transferData.fromSenderToPay
      ? transferData.reverse ? transferData.amountReceivedDest : transferData.amountSentSrc
      : '',
    destinationCurrency: transferData.destinationCurrency,
    fundingSource: transferData.payinType || 'CASH',
    operatorId: transferData.operatorId,
    organizationId,
    payoutType: transferData.payoutType,
    receiveCountry: transferData.destinationCountry,
    reverse: transferData.reverse,
    sendCountry: transferData.sourceCountry,
    sourceCurrency: transferData.sourceCurrency,
    senderId: userId,
    transactionOrigin: 'WEB',
    promoCode: transferData.promoCode,
    amountSenderToPay: transferData.amountSenderToPay || '',
    fromSenderToPay: Boolean(transferData.fromSenderToPay),
  }
  if (transferData.payoutType === 'CASH' && transferData.pickupLocations && transferData.pickupLocations.length) {
    payload.pickupCode = transferData.pickupLocations[0].locationCode
  }
  let locations = undefined
  if (transferData.payoutType === 'CASH' && !transferData.pickupLocations) {
    while (true) {
      const { pickupLocations } = yield select(({ transfer }) => transfer.transferData)
      if (!pickupLocations) {
        yield delay(50)
      } else {
        locations = pickupLocations
        break
      }
    }
    payload.pickupCode = locations.length ? locations[0].locationCode : ''
  }
  return { data: payload }
}

const getPriceFork = fork(actionsHandler, {
  actions: getPriceActions,
  method: 'POST',
  paramsGetter: getPricePayloadBody,
  responseParser: (response: { data: IPriceResponse }) => ({
    accountCurrency: response.data.accountCurrency,
    amountReceivedAcc: response.data.amountReceivedAcc,
    amountReceivedDest: response.data.amountReceivedDest || 0,
    amountSentInAcc: response.data.amountSentInAcc,
    amountSentSrc: response.data.amountSentSrc || 0,
    campaignData: response.data.campaignData,
    campaignDiscountAcc: response.data.campaignDiscountAcc,
    campaignDiscountSrc: response.data.campaignDiscountSrc,
    discountAmountFixedInAcc: response.data.discountAmountFixedInAcc,
    discountAmountFixedInSrc: response.data.discountAmountFixedInSrc,
    fxRateAccDestWithCommission: response.data.fxRateAccDestWithCommission,
    fxRateAccSrcWithCommission: response.data.fxRateAccSrcWithCommission,
    fxRateAccToDest: response.data.fxRateAccToDest,
    fxRateAccToSrc: response.data.fxRateAccToSrc,
    fxRateSrcDestCustom: response.data.fxRateSrcDestCustom,
    fxRateSrcDestWithCommission: response.data.fxRateSrcDestWithCommission,
    fxRateSrcToDest: response.data.fxRateSrcToDest,
    message: response.data.message,
    organizationFeeAmountAcc: response.data.organizationFeeAmountAcc,
    organizationFeeAmountSrc: response.data.organizationFeeAmountSrc,
    organizationFeeFixed: response.data.organizationFeeFixed,
    organizationFeeFixedInAcc: response.data.organizationFeeFixedInAcc,
    organizationFeePercent: response.data.organizationFeePercent,
    rateMode: response.data.rateMode,
    senderToPayAcc: response.data.senderToPayAcc,
    senderToPaySrc: response.data.senderToPaySrc,
  }),
  url: transferConstants.GET_PRICE_REQUEST,
})

function* locationsPayloadBody() {
  const { transferData } = yield select((state) => state.transfer)
  const payload = {
    // agentId,
    organizationId,
    country: transferData.destinationCountry,
    operatorId: transferData.operatorId,
    payoutType: transferData.payoutType,
  }
  return { data: payload }
}

function* branchesPayloadBody(payload: { bankCode: string }) {
  const { transferData } = yield select((state) => state.transfer)
  const requestPayload = {
    // agentId,
    organizationId,
    country: transferData.destinationCountry,
    operatorId: transferData.operatorId,
    bankCode: payload.bankCode,
  }
  return { data: requestPayload }
}

function* citiesPayloadBody() {
  const { transferData } = yield select((state) => state.transfer)
  const payload = {
    organizationId,
    country: transferData.destinationCountry,
    operatorId: transferData.operatorId,
  }
  return { data: payload }
}

function* stateCitiesPayloadBody() {
  const { transferData } = yield select((state) => state.transfer)
  const payload = {
    country: transferData.destinationCountry,
    operatorId: transferData.operatorId,
    state: transferData.state,
  }
  return { data: payload }
}

function* cityLocationsPayloadBody(payload: { city: string }) {
  const { transferData } = yield select((state) => state.transfer)
  const requestPayload = {
    organizationId,
    country: transferData.destinationCountry,
    operatorId: transferData.operatorId,
    city: payload.city,
  }
  return { data: requestPayload }
}

function* stateCityLocationsPayloadBody() {
  const { transferData } = yield select((state) => state.transfer)
  const requestPayload = {
    country: transferData.destinationCountry,
    operatorId: transferData.operatorId,
    state: transferData.state,
    city: transferData.pickupCityName,
  }
  return { data: requestPayload }
}

function* mobileOperatorsPayloadBody() {
  const { transferData } = yield select((state) => state.transfer)
  const requestPayload = {
    country: transferData.destinationCountry,
    operatorId: transferData.operatorId,
    sourceCountry: transferData.sourceCountry,
  }
  return { data: requestPayload }
}

function* validateTransactionPayloadBody() {
  const { transferData, transactionMeta } = yield select((state) => state.transfer)
  const payload = {
    agentId: transactionMeta.agentId,
    operatorId: transferData.operatorId,
    transactionId: transactionMeta.transactionId,
    requiredFields: transactionMeta.requiredFields,
  }
  return { data: payload }
}

const getLocationsFork = fork(actionsHandler, {
  actions: getLocationsActions,
  method: 'POST',
  paramsGetter: locationsPayloadBody,
  responseParser: ({ data }: { data: any }) => data.locationDetails,
  url: transferConstants.GET_PICKUP_LOCATIONS,
})

const getBanksFork = fork(actionsHandler, {
  actions: getBanksActions,
  method: 'POST',
  paramsGetter: locationsPayloadBody,
  responseParser: ({ data }: { data: any }) => data.bankDetails,
  url: transferConstants.GET_BANKS,
})

const getBranchesFork = fork(actionsHandler, {
  actions: getBranchesActions,
  method: 'POST',
  paramsGetter: branchesPayloadBody,
  responseParser: ({ data }: { data: any }) => data.bankBranchDetails,
  url: transferConstants.GET_BRANCHES_BANK,
})

const getCitiesFork = fork(actionsHandler, {
  actions: getCitiesActions,
  method: 'POST',
  paramsGetter: citiesPayloadBody,
  responseParser: ({ data }: { data: any }) => data.cities,
  url: transferConstants.GET_COUNTRY_CITIES,
})

const getCountryStatesFork = fork(actionsHandler, {
  actions: getCountryStatesActions,
  method: 'POST',
  paramsGetter: citiesPayloadBody,
  responseParser: ({ data }: { data: any }) => data.states,
  url: transferConstants.GET_COUNTRY_STATES,
})

const getStateCitiesFork = fork(actionsHandler, {
  actions: getStateCitiesActions,
  method: 'POST',
  paramsGetter: stateCitiesPayloadBody,
  responseParser: ({ data }: { data: any }) => data.cities,
  url: transferConstants.GET_STATE_CITIES,
})

const getCityLocationsFork = fork(actionsHandler, {
  actions: getCityLocationsActions,
  method: 'POST',
  paramsGetter: cityLocationsPayloadBody,
  responseParser: ({ data }: { data: any }) => data.cashPickupLocations,
  url: transferConstants.GET_CITY_LOCATIONS,
})

const getStateCityLocationsFork = fork(actionsHandler, {
  actions: getStateCityLocationsActions,
  method: 'POST',
  paramsGetter: stateCityLocationsPayloadBody,
  responseParser: ({ data }: { data: any }) => data.locations,
  url: transferConstants.GET_STATE_CITY_LOCATIONS,
})

const getMobileOperatorsFork = fork(actionsHandler, {
  actions: getMobileOperatorsActions,
  method: 'POST',
  paramsGetter: mobileOperatorsPayloadBody,
  responseParser: ({ data: { mobileOperatorDetails } }: { data: { mobileOperatorDetails: IMobileOperator[] } }) =>
    mobileOperatorDetails,
  url: transferConstants.GET_MOBILE_OPERATORS,
})

const validateTransactionFork = fork(actionsHandler, {
  actions: validateTransactionActions,
  method: 'POST',
  paramsGetter: validateTransactionPayloadBody,
  responseParser: ({ data }: { data: any }) => data.validationPassed,
  url: transferConstants.VALIDATE_TRANSACTION,
})

export default function* rootSaga() {
  yield all<ForkEffect>([
    yield takeEvery(setCalculatorData.toString(), makeCalculationSaga),
    yield takeEvery(makeTransactionActions[REQUEST].toString(), makeNewTransactionSaga),
    yield takeEvery(confirmTransactionActions[REQUEST].toString(), confirmTransactionSaga),
    yield takeEvery([makeTransactionActions[SUCCESS].toString(), validateTransactionActions[SUCCESS].toString()], navigateAfterMakeTransaction),
    yield takeEvery(getDTOneDataActions[REQUEST].toString(), getDToneDataSaga),
    yield takeEvery(getLocationsSettingsActions[REQUEST].toString(), getLocationsSettingsSaga),
    yield takeEvery(getDiscountActions[REQUEST].toString(), getDiscountSaga),
    getPriceFork,
    getLocationsFork,
    getBanksFork,
    getBranchesFork,
    getCitiesFork,
    getStateCitiesFork,
    getCityLocationsFork,
    getCountryStatesFork,
    getStateCityLocationsFork,
    getMobileOperatorsFork,
    validateTransactionFork,
  ])
}
